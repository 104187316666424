<template>
  <div class="auth-v1" style="padding-top: 0px">
    <loaderdialog :loader="{text:'Traitement en cours', power: startload}" v-if="startload"/>
    <div v-if="!startload" class="content-inner">
      <breadcrumbs :items="dataBreadcrumbs" />
      <v-row>
        <v-col xs="12" class="col-xs-12">
          <h2 style="font-size: 25px !important;">Informations sur le colis</h2>
        </v-col>
        <v-col xs="12" class="col-xs-12">
        </v-col>
      </v-row>
      <v-card elevation="0">
          <v-card-text>
            <v-row no-gutters>
              <v-col
                  cols="12"
                  sm="6"
                  md="8"
              >
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                  <div class="font-weight-bold" style="font-size: 15px">
                    Colis N° <small>{{orderdetail.id}}</small> du <small>{{orderdetail.created_at}}</small> <br/>
                    Départ: <small>{{orderdetail.sender.town}}</small> <br/>
                    Arrivée: <small>{{orderdetail.receiver.town}}</small> <br/>
                    N° Fact.: <small>{{ orderdetail.num_facture }}</small><br/>
                    Code de tracking: <small>{{orderdetail.tracking_code}}</small><br/>
                    Ref. paiement: <small>{{orderdetail.paymentreference}}</small><br/>
                    Mode de livraison: <small>{{orderdetail.deliverymode}}  </small><br/>
                    Status: <small>{{orderdetail.status.label}}</small><br/>
                  </div>
                </v-card>
              </v-col>
              <v-col
                  cols="6"
                  md="4"
              >
                <v-card
                    outlined
                    tile
                    class="pa-2 align-center"
                >
                  <v-img width="120" :src="orderdetail.qrcode"></v-img>

                  <v-btn
                      color="primary"
                      small
                      :to="'/app/fr/ticket-pdf?id='+orderdetail.id"
                      v-bind="attrs"
                      target="_blank"
                      v-on="on"
                      @click="loader = 'loading'"
                  >IMPRIMER</v-btn>
                </v-card>
              </v-col>
              <!-- alert -->
              <v-col cols="12">
                <v-alert
                    v-if="orderdetail.affiliate_id"
                    color="success"
                    text
                    class="mb-0"
                >
                  <div class="d-flex align-start">
                    <v-icon color="success">
                      {{ icons.mdiThumbUp }}
                    </v-icon>
                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        Ce colis est déjâ affectée au livreur : <b>{{orderdetail.affiliate.user.firstname}} {{orderdetail.affiliate.user.lastname}} </b>, Email : <b>{{orderdetail.affiliate.user.email}}</b>
                      </p>
                    </div>
                  </div>
                </v-alert>
                <v-alert
                    v-else
                    color="warning"
                    text
                    class="mb-0"
                >
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        Ce colis est en attente d'affectation
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
              >
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                  <h4 class="text-xl font-weight-semibold mb-2">
                    Expéditeur
                  </h4>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item
                        dense
                        class="px-0 mb-n2"
                    >
                      <span class="text--secondary">Nom Complet: {{ orderdetail.sender.firstname }} {{orderdetail.sender.lastname}} <br> Pays: {{ orderdetail.sender.country.iso }} <br> Tél: {{ orderdetail.sender.phonenumber }} <br> Ville:{{ orderdetail.sender.town }} <br> Adresse: {{ orderdetail.sender.address }} <br> BP: {{ orderdetail.sender.postalcode }}</span>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                  cols="6"
                  md="6"
              >
                <v-card
                    class="pa-2"
                    outlined
                    tile
                >
                  <h4 class="text-xl font-weight-semibold mb-2">
                    Recepteur
                  </h4>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item
                        dense
                        class="px-0 mb-n2"
                    >
                      <span class="font-weight-medium me-2"></span>
                      <span class="text--secondary">Nom Complet: {{ orderdetail.receiver.firstname }} {{orderdetail.receiver.lastname}} <br> Pays: {{ orderdetail.receiver.country.iso }} <br> Tél: {{ orderdetail.receiver.phonenumber }} <br> Ville:{{ orderdetail.receiver.town }} <br> Adresse: {{ orderdetail.receiver.address }} <br> BP: {{ orderdetail.receiver.postalcode }}</span>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>


            <v-row justify="space-around">
              <v-col cols="auto">
                <v-dialog
                    transition="dialog-top-transition"
                    max-width="800"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-row no-gutters>
                      <hr/>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-card
                            class="pa-2"
                            outlined
                            tile
                        >

                        </v-card>
                      </v-col>
                      <v-col
                          cols="12"
                          sm="12"
                          md="12"
                      >
                        <v-card
                            v-if="orderdetail.status._key != 'complete'"
                            class="float-right"
                            outlined
                        >
                          <v-btn
                              v-if="!orderdetail.affiliate_id"
                              color="primary"
                              small
                              v-bind="attrs"
                              v-on="on"
                              @click="loader = 'loading'"
                          >Attribuer le colis</v-btn>

                          <v-btn
                              v-if="orderdetail.affiliate_id"
                              color="warning"
                              small
                              v-bind="attrs"
                              v-on="on"
                              @click="loader = 'loading'"
                          >Modifier l'attribution du colis</v-btn>

                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-card-title class="align-start">
                        <span>Attribuer le colis a un livreur</span>
                        <v-spacer></v-spacer>
                        <v-col cols="12">
                            <v-alert
                            v-show="alert.active"
                            :color="alert.color"
                            text
                            class="mb-0"
                        >
                          <div class="d-flex align-start">
                            <v-icon :color="alert.color">
                              {{ icons.mdiAlertOutline }}
                            </v-icon>

                            <div class="ms-3">
                              <p class="text-base font-weight-medium mb-1">
                                {{ alert.text }}
                              </p>
                            </div>
                          </div>
                        </v-alert>
                        </v-col>
                      </v-card-title>
                      <v-card-text>
                        <v-form
                            ref="form"
                            @submit.prevent="validate"
                        >
                          <v-row no-gutters>
                            <v-col
                                cols="12"
                                sm="6"
                                md="8"
                            >
                              <v-card
                                  class="pa-2"
                                  outlined
                                  tile
                              >
                                <div class="font-weight-bold" style="font-size: 15px">
                                  Colis N° <small>{{orderdetail.id}}</small> du <small>{{orderdetail.created_at}}</small> <br/>
                                  Départ: <small>{{orderdetail.sender.town}}</small> <br/>
                                  Arrivée: <small>{{orderdetail.receiver.town}}</small> <br/>
                                  N° Fact.: <small>{{ orderdetail.num_facture }}</small><br/>
                                  Code de tracking: <small>{{orderdetail.tracking_code}}</small><br/>
                                  Ref. paiement: <small>{{orderdetail.paymentreference}}</small><br/>
                                  Mode de livraison: <small>{{orderdetail.deliverymode}}</small><br/>
                                  Status: <small>{{orderdetail.status.label}}</small><br/>
                                </div>
                              </v-card>
                            </v-col>
                            <v-col
                                cols="6"
                                md="4"
                            >
                              <v-card
                                  class="pa-2"
                                  outlined
                                  tile
                              >
                                <v-img width="120" :src="orderdetail.qrcode"></v-img>
                              </v-card>
                            </v-col>
                          </v-row>
                          <div class="d-flex pa-2 align-center justify-space-between flex-wrap">
                          <v-radio-group
                              v-model="livreurs"
                              row
                          >
                            <v-radio
                                label="Tous les livreurs"
                                key="tous"
                                value="tous"
                            ></v-radio>
                            <v-radio
                                label="Livreurs disponibles"
                                key="disponible"
                                value="disponible"
                            ></v-radio>
                          </v-radio-group>
                          </div>
                          <div class="d-flex pa-2 align-center justify-space-between flex-wrap">
                            <v-combobox
                                v-model="affiliate"
                                :filter="filter"
                                :hide-no-data="!search"
                                :items="items"
                                :search-input.sync="search"
                                hide-selected
                                label="Rechercher un livreur"
                                menu-props="auto"
                                hide-details
                                single-line
                                small-chips
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-chip
                                      color="error"
                                      label
                                      small
                                  >
                                    {{ search }}
                                  </v-chip>
                                </v-list-item>
                              </template>
                              <template v-slot:selection="{ attrs, item, parent, selected }">

                                <v-chip
                                    v-if="item === Object(item)"
                                    v-bind="attrs"
                                    :color="`error lighten-6`"
                                    :input-value="selected"
                                    label
                                    small
                                >
          <span class="pr-2">
            {{ item.text }}
          </span>
                                  <v-icon
                                      small
                                      @click="parent.selectItem(item)"
                                  >
                                    $delete
                                  </v-icon>
                                </v-chip>
                              </template>
                              <template v-slot:item="{ index, item }">
                                <v-btn
                                    v-if="livreurs != 'tous'"
                                    class="ma-2"
                                    outlined
                                    small
                                    color="indigo"
                                >
                                  {{ item.text }} -
                                {{ item.colis }} -
                                Date de départ: {{ item.date_from }} -
                                Date d'arrivée: {{ item.date_to }}
                                </v-btn>

                                <v-chip
                                    v-else
                                    color="success"
                                    dark
                                    label
                                    small
                                >
                                  {{ item.text }}
                                </v-chip>
                              </template>
                            </v-combobox>
                          </div>

                        </v-form>

                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                            color="error"
                            text
                            @click="dialog.value = false"
                        >
                          Fermer
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            :loading="loading"
                            :disabled="loading"
                            color="primary"
                            small
                            dark
                            @click="confirmAssign(orderdetail)"
                        >
                          <v-icon
                              left
                              dark
                          >
                            mdi-content-save-check
                          </v-icon>
                          Enregistrer
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
    </div>
  </div>
</template>

<script>

import breadcrumbs from "../components/ui/breadcrumbs";
import loaderdialog from "@/components/ui/loaderdialog";
import {mdiAlertOutline, mdiAlertRemove, mdiThumbUp, mdiHome} from '@mdi/js'
import {Drequest} from "../plugins/Drequest";

export default {
  name: "DetailCourse",
  components: {
    breadcrumbs,
    loaderdialog
  },
  data: () => ({
    startload: true,
    dataBreadcrumbs: [],
    livreurs: 'tous',
    loader: null,
    disabled: false,
    orderdetail: "",
    activator: null,
    attach: null,
    colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
    editing: null,
    editingIndex: -1,
    items: [
      { header: 'Selectionner un livreur' },
    ],
    nonce: 1,
    menu: false,
    affiliate: [],
    x: 0,
    search: null,
    y: 0,
    tab: {},
    loading: false,
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    social_reasons: [],
    checkbox: true,
    valid: true,
    dialog: false,
    response: {},
    transporter: {},
    icons: {
      mdiAlertRemove,
      mdiAlertOutline,
      mdiThumbUp,
      mdiHome
    },
    inprocess: false,
    alert: {
      color: 'warning',
      text: 'Ce Colis est en attente de d\'attribution',
      icons: mdiAlertOutline,
      active: true
    },
  }),
  props: ["user"],
  beforeMount() {
    this.startload = true;
  },
  mounted() {
    this.dataBreadcrumbs = [
      {
        text: 'Accueil',
        disabled: false,
        href: '/dashboard',
      },
      {
        text: 'liste des colis',
        disabled: false,
        href: '/settings/course',
      },
      {
        text: 'detail du colis',
        disabled: true,
        href: '#',
      },
      {
        text: 'Colis N° :'+this.$route.params.id,
        disabled: true,
        href: '#',
      }
    ];
    this.initialize(this.$store.state.transporter.id, this.livreurs);
  },
  watch: {
    livreurs (item) {
      this.initialize(this.$store.state.transporter.id, item);
    },
    model (val, prev) {
      if (val.length === prev.length) return

      this.model = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          }

          this.items.push(v)

          this.nonce++
        }

        return v
      })
    },
  },
  methods: {
    confirmAssign(order) {
      this.loading = true
      Drequest.api("order.assign?id="+order.id).data({"order":{"affiliate.id": this.affiliate.id}}).raw((response)=>{
        if(response.success == true){
          this.alert = {
            color: "success",
            active: true,
            text: "Ce colis a été a attribuer! Affiliate: (" + this.affiliate.text + "), Numero Tracking Colis: "+response.order.tracking_code,
            icons: this.icons.mdiThumbUp
          }
        }else {
          this.alert = {
            color: "error",
            active: true,
            text: "Une Erreur c'est produite lors de la création !!!",
            icons: this.icons.mdiAlertRemove
          }
        }
      })
      this.loading = false
    },
    initialize(id, typelivreur) {
      this.items = [
        { header: 'Selectionner un livreur' },
      ];
      if(typelivreur == "tous"){
        Drequest.api("dcollection")
            .data({
              "lazyloading.affiliate": "dfilters=on&transporter.id:eq="+id,
              "detail.order": this.$route.params.id
            })
            .raw((response) => {
              this.orderdetail = response.order.order;
              response.affiliate_ll.listEntity.forEach(item => {
                this.items.push({
                  text: item.user.firstname +' '+item.user.lastname +' Nombre de colis ('+item.count_colis+')',
                  id: item.id,
                });
              })
              this.startload = false;
              // this.close();
            });
      }else{
        Drequest.api("disponibility.listbydestination?arrival="+this.orderdetail.sender.town+"&departure="+this.orderdetail.receiver.town)
            .raw((response) => {
              this.loading = false;
              response.disponibility.forEach((item) => {
                this.items.push({
                  text: ' Nom: ' +item.affiliate.user.firstname +' '+item.affiliate.user.lastname,
                  colis: ' Nombre de colis: ('+item.affiliate.count_colis+')',
                  date_from: item.date_from,
                  date_to: item.date_to,
                  id: item.affiliate.id,
                });
              })
              this.startload = false;
            })
      }
    },
    filter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
    }
  }
}
</script>

<style scoped>
  .content-inner {
    width: 100% !important;
    z-index: 1;
  }
  .theme--light.v-sheet--outlined {
    border: none !important;
    /* border: thin solid rgba(94, 86, 105, 0.14); */
  }
</style>